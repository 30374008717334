import { Injectable } from '@angular/core';
import { Operator } from '@enums/operator';
import { MatrixCellCount } from '@type/strategy.type';
import { StrategyBodyCellComponent } from '../../components/strategy-body-cell/strategy-body-cell.component';

@Injectable()
export class TableProcessService {
  rewriteCells(cell: StrategyBodyCellComponent, index: 0 | 1) {
    const changes = this.getValueChanges(cell);

    this.applyLimits(cell, cell.matrixValue.value()[index]);
    this.setPreviousValue(cell);

    if (changes) {
      this.setStrategyChanges(cell);
      this.processParentRows(cell, changes, index);
    }
  }

  private processParentRows({ row, item }: StrategyBodyCellComponent, changes: MatrixCellCount, index: 0 | 1) {
    let r = row();
    const id = item().id;

    while (r.parent) {
      r = r.parent;
      const parentCell = r.data[id];
      parentCell.value.update((v) => (index === 0 ? [v[0] + changes.min, v[1]] : [v[0], v[1] + changes.max]));
      {
        if (r.expandable) {
          parentCell.hasInnerDeviation.set(
            !!r.children?.some((cr) => cr.data[id].hasInnerDeviation() || cr.data[id].deviated()),
          );
        }
      }
    }

    this.updateCapacity(item, changes);
  }

  private updateCapacity(item: StrategyBodyCellComponent['item'], changes: MatrixCellCount) {
    let item2 = item();
    item2 = { ...item2, current_quantity: (item2.current_quantity += changes.max) };
  }

  private setStrategyChanges(cmp: StrategyBodyCellComponent) {
    cmp.state.setStrategyChanges(cmp);
  }

  private getValueChanges({ matrixValue, prevCount }: StrategyBodyCellComponent) {
    return { min: matrixValue.value()[0] - prevCount.min, max: matrixValue.value()[1] - prevCount.max };
  }

  private setPreviousValue(cell: StrategyBodyCellComponent) {
    cell.prevCount = { min: cell.matrixValue.value()[0], max: cell.matrixValue.value()[1] };
  }

  private applyLimits(cell: StrategyBodyCellComponent, newValue: number) {
    cell.attachedLimits ??= this.getAttachedLimits(cell);
    cell.deviatedLimits = this.getDeviatedLimits(cell, newValue);
    cell.matrixValue.deviated.set(!!cell.deviatedLimits.length);
  }

  private getDeviatedLimits({ matrixValue, strategy, attachedLimits }: StrategyBodyCellComponent, value: number) {
    if (attachedLimits) {
      return attachedLimits.filter((l) => Function(`return ${value}${this.invertOperator(l.operator)}${l.value};`)());
    }
    return strategy()?.strategy_limits.filter(({ id }) => matrixValue.deviatedLimitIds.includes(id)) || [];
  }

  private getAttachedLimits({ matrixValue, strategy }: StrategyBodyCellComponent) {
    return (
      strategy()?.strategy_limits.filter(
        ({ id }) => matrixValue.attachedLimitIds.includes(id) || matrixValue.deviatedLimitIds.includes(id),
      ) || []
    );
  }

  private invertOperator(operator: Operator) {
    switch (operator) {
      case Operator['=']:
        return Operator['!='];
      case Operator['!=']:
        return '==';
      case Operator['<']:
        return Operator['>='];
      case Operator['>']:
        return Operator['<='];
      case Operator['<=']:
        return Operator['>'];
      case Operator['>=']:
        return Operator['<'];
      default:
        return '==';
    }
  }
}
