import { Injectable, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { firstValueFrom, map, startWith } from 'rxjs';

@Injectable()
export class StrategyTableSettingsService {
  tableSettingsForm = new FormGroup({ min: new FormControl(false), max: new FormControl(true) });

  cdrTrigger = signal(Date.now());

  disabledControl = this.tableSettingsForm.valueChanges.pipe(
    startWith(this.tableSettingsForm.value),
    map(() => {
      const { max, min } = this.tableSettingsForm.getRawValue();
      if (+!!max + +!!min === 2) return '';
      return (!!max && 'max') || (min && 'min') || '';
    }),
  );

  settingsOpts = {
    title: 'column_settings',
    callbacks: {
      reset: () => this.tableSettingsForm.reset({ max: true, min: false }),
      apply: () => this.applySettings(),
    },
  };

  table = {
    style: { 'grid-template-columns': '1fr' },
    visibility: { max: true, min: false },
  };

  async applySettings() {
    const disabledControl = await firstValueFrom(this.disabledControl.pipe());
    if (!!disabledControl) {
      this.table.visibility = disabledControl === 'max' ? { max: true, min: false } : { max: false, min: true };
      this.table.style = { 'grid-template-columns': '1fr' };
    } else {
      this.table.visibility = { max: true, min: true };
      this.table.style = { 'grid-template-columns': '1fr 1fr' };
    }

    this.cdrTrigger.set(Date.now());
  }
}
